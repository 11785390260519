
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useridchecks, lastjoinafter } from '../../service/project';
import MetaTag from "../../MetaTag";
import { metaInfoForProjectAddWritePage } from "../../service/mataTags";

import Swal from 'sweetalert2';
function Addprojectsjoin({ uid, setCookieIds, getCookieIds }) {
  const [addUser, setAddUser] = useState({
    userId: '',
    email: '',
    uPassword: '',
    uNewPassword: '1'
  })
  const [error, setError] = useState({
    lengerr: '6자 이상으로 입력해주세요',
    matcherr: '비밀번호가 일치하지 않습니다',
    lengerr: '6자 이상으로 입력해주세요',

  });
  const [pass, setPass] = useState(false)
  const [check, setCheck] = useState(false)
  const [idcheck, setIdCheck] = useState(false)
  const navigate = useNavigate()

  const handleChange = (id, value) => {
    setAddUser(prevState => ({
      ...prevState,
      [id]: value
    }));

  };

  const useridCheck = (e) => {
    e.preventDefault();
    if (addUser.userId) {
      useridchecks(addUser.userId)
        .then(function (response) {
          if (response.data === true) {
            Swal.fire(
              '이미 등록된 아이디 입니다',
              '확인 버튼을 눌러 주세요',
              'error'
            )
          } else {
            Swal.fire(
              '사용가능한 아이디 입니다',
              '확인 버튼을 눌러 주세요',
              'success'
            )
            setIdCheck(true)
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    } else {
      Swal.fire('아이디를 입력해주세요')
    }
  }
  const removeUidFromCookies = (uidToRemove) => {
    const existingIds = getCookieIds();
    const updatedIds = existingIds.filter(uid => uid !== uidToRemove);
    setCookieIds(updatedIds);
  };
  const registration = async (e) => {
    e.preventDefault();
    if (!addUser.email) {
      Swal.fire('이메일을 입력해주세요')
    } else if (!addUser.userId) {
      Swal.fire('아이디를 입력해주세요')
    } else if (!addUser.uPassword) {
      Swal.fire('비밀번호를 입력해주세요')
    } else if (addUser.uNewPassword == '1') {
      Swal.fire('비밀번호확인을 입력해주세요')
    } else if (!check) {
      Swal.fire('체크박스에 동의해주세요')
    } else if (!idcheck) {
      Swal.fire('아이디를 중복확인 해주세요')
    } else {
      lastjoinafter(addUser.uPassword, addUser.email, addUser.userId, uid)
        .then(function (response) {
          if (response.data) {
            Swal.fire(
              '회원가입이 완료 되었습니다',
              '확인 버튼을 눌러 주세요',
              'success'
            )
            removeUidFromCookies(uid);
            navigate(`/Addprojects/add/fine?uid=${uid}`);
          }
        })
        .catch(function (error) {
          console.log(error)
        })

    }
  }
  let regPass = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{6,25}$/;
  const regEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  return (
    <div className="login_box join">
      <MetaTag {...metaInfoForProjectAddWritePage} />
      <form >
        <div style={{ borderBottom: "2px solid rgba(16, 36, 148, 0.096", marginBottom: '20px' }}>
          <h3 className='clientInfo_title'>SIGN UP</h3>
        </div>
        <input className='projectAdd_inputbox mb10' name='email' type="text" placeholder="E-mail" onChange={e => handleChange('email', e.target.value)} />
        {
          !regEmail.test(addUser.email) ? <p className='passerror'>이메일 형식을 확인해주세요</p> : ''
        }
        <div className='flex j a'>
          <input className='projectAdd_inputbox mb10 width_100s' name='userId' type="text" placeholder="ID" onChange={e => handleChange('userId', e.target.value)} />
          <button className='navibtn_hover p10 ml10 mb10 c' onClick={e => { useridCheck(e) }}>중복확인</button>
        </div>

        <input className='projectAdd_inputbox mb10' onClick={e => setPass(true)} type="password" placeholder="Password" onChange={e => handleChange('uPassword', e.target.value)} />
        {pass ?
          <p className={!regPass.test(addUser.uPassword) ? 'passerror' : 'pass_success'}>	영문/숫자/특수문자 조합(6~20자) {regPass.test(addUser.uPassword) ? <span>○</span> : ''}</p>
          : ""
        }
        <input className='projectAdd_inputbox mb10' name='new-password' type="password" placeholder="Password 확인" onChange={e => handleChange('uNewPassword', e.target.value)} />
        {addUser.uPassword === addUser.uNewPassword && addUser.uNewPassword.length >= 6 ?
          <p className='pass_success'>비밀번호가 일치합니다</p>
          : addUser.uPassword !== addUser.uNewPassword && addUser.uNewPassword.length >= 2 ? <p className='passerror'>비밀번호가 일치하지 않습니다</p>
            : ''
        }
        <label className='flex mt10'>
          <input onChange={e => setCheck(!check)} type='checkbox' /> 동의하시겠습니까
        </label>
        <div className='flex mt40'>
          <button className='navibtn_hover p10 mr10' onClick={e => registration(e)} type='submit'>회원가입</button>
          <Link onClick={() => { removeUidFromCookies(uid) }} className='navibtn_hover p10' to={`/Addprojects/add/fine?uid=${uid}`}>다음에 가입하기</Link>
        </div>
      </form>
    </div>
  )
}


export default Addprojectsjoin;
