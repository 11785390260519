import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import React from 'react';
import Header from './Header';
import Footer from './Footer';
import Main from './components/Main';
import Company from './components/Company';
import Login from './components/Login';
import Signin from './components/Signin';
import Addprojectsadd from './components/Addprojects/Addprojectsadd';
import Admin from './components/Admin/Admin';
import Myprojects from './components/Mypage/Myprojects';
import Myinfo from './components/Mypage/Myinfo';
import Confidentiality from './components/Addprojects/Confidentiality';
import Outsourcing from './components/Outsourcings/Outsourcing';
import MHeaders from './MHeader/MHeaders';
import Others from './components/Othres/Others';
import Rule from './components/Addprojects/Rule';
import Mymessage from './components/Mypage/Mymessage';
import { PC, Tablet } from "./MediaQuery"
import { useRealTimeData } from './RealTimeDataContext';
import Portfolio from './notused/Portfolio';
const Approuter = ({ isPc }) => {
  const { setDisplayTime, setMlist, mlist, token } = useRealTimeData();

  return (

    <div className='contain'>
      <BrowserRouter>
        <PC>
          <Header />
        </PC>
        <Tablet>
          <MHeaders />
        </Tablet>

        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/Company" element={<Company />} />
          <Route path="/Portfolio" element={<Portfolio />} />
          <Route path="/Addprojects/rule" element={<Rule userId={token && token.userId} />} />
          <Route path="/Addprojects/confidentiality" element={<Confidentiality userId={token && token.userId} login={token} />} />
          <Route path="/Addprojects/add" element={<Addprojectsadd login={token} userId={token && token.userId} />} />
          <Route path="/Addprojects/add/:id" element={<Addprojectsadd login={token} userId={token && token.userId} />} />
          <Route path="/Outsourcing/:id" element={<Outsourcing userId={token && token.userId} login={token} />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Signin" element={<Signin />} />
          <Route path="/Mypage/Myprojects" element={<Myprojects userId={token && token.userId} userInfo={token} />} />
          <Route path="/Others/:id" element={<Others />} />
          <Route path="/Mypage/Myprojects/:id" element={<Myprojects userInfo={token} userId={token && token.userId} />} />
          <Route path="/Mypage/Myprojects/:id/:id" element={<Myprojects userInfo={token} userId={token && token.userId} />} />
          <Route path="/Mypage/Myinfo" element={<Myinfo userInfo={token} />} />
          <Route path="/Mypage/Myinfo/:id" element={<Myinfo userInfo={token} />} />
          <Route path="/Mypage/Myinfo/:id/:id" element={<Myinfo userInfo={token} />} />
          <Route path="/MYMESSAGE/:id" element={<Mymessage mlist={mlist} setMlist={setMlist} userInfo={token} userId={token && token.userId} />} />

          {token && token.uGrade === 1 ?
            <Route path="/Admin/:id" element={<Admin />} />

            : ''}

          <Route path='*' element={<Navigate to="/" replace />} />
        </Routes>



        <Footer />
      </BrowserRouter>
    </div>
  )
}

export default Approuter;