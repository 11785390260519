
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { alertreturn, lastjoin, useridchecks } from '../service/project';
import Swal from 'sweetalert2';
import MetaTag from '../MetaTag';
import { metaSignin } from '../service/mataTags';
import { AiOutlineArrowLeft } from "react-icons/ai";
function Signin() {
  const [addUser, setAddUser] = useState({
    userId: '',
    email: '',
    uPassword: '',
    uNewPassword: '1'
  })
  const [error, setError] = useState({
    lengerr: '6자 이상으로 입력해주세요',
    matcherr: '비밀번호가 일치하지 않습니다',
    lengerr: '6자 이상으로 입력해주세요',

  });
  const [pass, setPass] = useState(false)
  const [truepass, setTruepass] = useState(false)
  const navigate = useNavigate()

  const handleChange = (id, value) => {
    setAddUser(prevState => ({
      ...prevState,
      [id]: value
    }));

  };
  //  useEffect(() => {
  //   setPasswordsMatch(addUser.uPassword === addUser.uNewPassword);
  // }, [addUser.uPassword, addUser.uNewPassword]);

  const useridCheck = (e) => {
    e.preventDefault();
    useridchecks(addUser.userId)
      .then(function (response) {
        if (response.data === true) {
          Swal.fire(
            '이미 등록된 아이디 입니다.',
            '확인 버튼을 눌러 주세요',
            'warning'
          )
        } else {
          Swal.fire(
            '사용가능한 아이디 입니다.',
            '확인 버튼을 눌러 주세요',
            'success'
          )
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const registration = async (e) => {

    e.preventDefault();
    if (!addUser.email) {
      alertreturn('loses', '이메일을')
    } else if (!addUser.userId) {
      alertreturn('loses', '아이디를')
    } else if (!addUser.uPassword) {
      alertreturn('loses', '비밀번호를')
    } else if (!addUser.uNewPassword) {
      alertreturn('loses', '비밀번호확인을')
    } else if (!truepass) {
      alertreturn('checkno')
    } else {
      lastjoin(addUser.uPassword, addUser.email, addUser.userId)
        .then(function (response) {
          if (response.data) {
            Swal.fire(
              '가입되었습니다',
              '확인 버튼을 눌러 주세요',
              'success'
            )
            navigate("/");
          }
        })
        .catch(function (error) {

        })
        .then(function () {
          // authService.signOut();
        });
    }

  }
  let regPass = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{6,25}$/;
  const regEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return (
    <section className="login">
      <MetaTag {...metaSignin} />
      <div className="login_box">
        <div className="left">
          <div className="top_link">
            <a className='flex a' href="/">
              <span className='mr5'><AiOutlineArrowLeft /></span>  Return home
            </a>
          </div>
          <div className="contact">
            <form >
              <h3>SIGN UP</h3>
              <input name='email' type="text" placeholder="이메일" onChange={e => handleChange('email', e.target.value)} />
              {
                addUser.email && !regEmail.test(addUser.email) ? <p className='passerror'>이메일을 입력해주세요</p> : ''
              }
              <div className='flex j a'>
                <input className='userId' name='userId' type="text" placeholder="아이디" onChange={e => handleChange('userId', e.target.value)} />
                <button className='savebtn' onClick={e => { useridCheck(e) }}>중복확인</button>
              </div>

              <input onClick={e => setPass(true)} type="password" placeholder="비밀번호" onChange={e => handleChange('uPassword', e.target.value)} />
              {pass ?
                <p className={!regPass.test(addUser.uPassword) ? 'passerror' : 'pass_success'}>	영문/숫자/특수문자 조합(6~20자) {regPass.test(addUser.uPassword) ? <span>○</span> : ''}</p>
                : ""
              }
              <input name='new-password' type="password" placeholder="비밀번호확인" onChange={e => handleChange('uNewPassword', e.target.value)} />
              {addUser.uPassword === addUser.uNewPassword && addUser.uNewPassword.length >= 6 ?
                <p className='pass_success'>비밀번호가 일치합니다</p>
                : addUser.uPassword !== addUser.uNewPassword && addUser.uNewPassword.length >= 2 ? <p className='passerror'>비밀번호가 일치하지 않습니다</p>
                  : ''
              }
              <label className='flex a c f14'>
                <input onChange={() => { setTruepass(!truepass) }} className='check mr10' type='checkbox' /> 회원 가입에 동의 하시겠습니까 ?
              </label>
              <button className='pointer projectbtn' onClick={e => registration(e)} type='submit'>회원가입</button>
            </form>
          </div>
        </div>
        <div className="right">
          <img src='https://ifh.cc/g/FgfJ48.jpg ' alt='외주개발 니즈로봇 가입 페이지' />
          <div className="right-text">
            <h2>JOIN US</h2>
            <h5>NEEDS ROBOT</h5>
          </div>
          <div className="right-inductor"></div>
        </div>

      </div>
    </section>
  );
}

export default Signin;