
import React from 'react';
import Subhead from '../Subhead';
import { useLocation, useNavigate } from 'react-router-dom';
import Agreement from './Agreement';
import Privacypolicy from './Privacypolicy';
import { metaAgreement, metaPrivacypolicy } from '../../service/mataTags';
import MetaTag from '../../MetaTag';
import { imageArray } from '../../service/dataarray';

function Others({ userId }) {
  const location = useLocation()
  const path = location.pathname.split("/")[2]
  const pathMeta = path === 'Agreement' ? metaAgreement : metaPrivacypolicy
  return (
    <div className='heightAuto'>
      <MetaTag {...pathMeta} />
      <div className='backback greyb'></div>
      <div className="project  ">
        <Subhead value={path === 'Agreement' ? '이 용 약 관' : '개인정보처리방침'} subvalue={''} img={imageArray.subtitle_portfolio} />

        {path === 'Agreement' ?
          <Agreement />
          : path === 'Privacypolicy' ?
            <Privacypolicy />
            : ''
        }


      </div>
    </div>
  );
}

export default Others;