import React from "react";
import { useState, useEffect } from "react";
import Subhead from "../Subhead";
import { FcProcess } from "react-icons/fc";
import { imageArray, rule_left } from "../../service/dataarray";
import Swal from "sweetalert2";
import Rule_project1 from "./Rule_project1";
import RuleProject2 from "./Rule_project2";
import MetaTag from "../../MetaTag";
import { metaAddprojectsRole } from "../../service/mataTags";
function Rule() {
  const [isHeaderFixeds, setIsHeaderFixeds] = useState(false);

  const [num, setNum] = useState(0);
  const handleScrollAndResize = () => {
    const newIsHeaderFixed = window.scrollY >= 330;
    setIsHeaderFixeds(newIsHeaderFixed);

    const parentElement = document.querySelector(".addproject");
    const fixedElement = document.querySelector(
      ".add_left_projectlistbox_1.fixed"
    );
    const fixedElements = document.querySelector(".add_left_projectlistbox_1");

    if (parentElement && fixedElement) {
      const parentRect = parentElement.getBoundingClientRect();
      fixedElement.style.left = parentRect.left + "px";
    } else {
      fixedElements.style.left = 0 + "px";
    }
  };
  useEffect(() => {
    window.addEventListener("resize", handleScrollAndResize);

    return () => {
      window.removeEventListener("resize", handleScrollAndResize);
    };
  }, []);
  const countNext = (value) => {
    if (value === 1 && num === 0) {
      setNum(num + 1)
    } else if (value === 1 && num === 1) {
      Swal.fire({
        title: '마지막 페이지 입니다',
        icon: 'warning'
      })
    } else if (value === -1 && num === 0) {
      Swal.fire({
        title: '처음 페이지 입니다',
        icon: 'warning'
      })
    } else if (value === -1) {
      setNum(num - 1)
    }
  }
  return (
    <div className='heightAuto'>
      <MetaTag {...metaAddprojectsRole} />
      <div className="backback greyb"></div>
      <div className="project">
        <Subhead
          value={"PROJECTS RULE"}
          subvalue={"프로젝트진행방법"}
          img={
            imageArray.subtitle_portfolio
          }
        />
        {num >= 0 ? (
          <div
            className={
              isHeaderFixeds
                ? "w1480 h100 flex addproject r"
                : "w1480 h100 flex addproject j"
            }
          >
            <div
              className={
                isHeaderFixeds
                  ? "add_left_projectlistbox_1 fixed"
                  : "add_left_projectlistbox_1"
              }
            >
              <div className="step_title flex a mb10">
                <FcProcess style={{ fontSize: "20px", marginBottom: "10px" }} />
                <h1 className="mr10 ml5 f20">프로젝트 진행방법</h1>
              </div>
              <div class="horizontal-line"></div>
              <div className="flex centerl projectnav">
                {rule_left.map((value, index) => (
                  <p
                    key={value.value}
                    index={value.value}
                    className={num === value.value ? "active" : "acvtives"}
                    onClick={() => setNum(index)}
                  // style={{cursor: 'pointer'}}
                  >
                    {index + 1}. {value.label}
                  </p>
                ))}
              </div>
              <div className="offerbtn_wrap flex absol c">
                <button
                  className={"ps navibtn_hover w33 ml10"}
                  onClick={() => countNext(-1)}
                >
                  이전
                </button>

                {num < 2 && (
                  <button
                    className={"navibtn_hover w33 ps ml10"}
                    onClick={() => countNext(1)}
                  >
                    다음
                  </button>
                )}

              </div>
            </div>
            <div className="add_right_wrap ">
              {num === 0 ? <Rule_project1 /> : <RuleProject2 />}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Rule;
